import React, { Fragment } from "react";

class Contact extends React.Component {

    constructor(props) {
        super(props);
    }

    render () {
        return(
            <Fragment>
                <h1>Kontakt</h1>
                <p>Mitt Klassrum är utvecklat som ett pedagogiskt verktyg för lärare. Inspiration är hämtad från andra liknande verktyg men istället för att registrera grupper som lagras i någon annans databas klistrar du snabbt och enkelt in din grupplista i textrutan. Sedan är det bara att köra.</p>  
                <p>Resultatet blir ett snabbt, lättarbetat och PUL-vänligt organiseringsverktyg för klassrummet.</p>
                <p>Hör gärna av dig till <a href="mailto:info@katederundervisning.se">info@katederundervisning.se</a> om du har synpunkter på förbättringar eller bara vill säga att du uppskattar verktyget.</p>
                <p>/katederundervisning.se</p>
            </Fragment>
        );
    }
}

export default Contact;