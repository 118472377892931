import React from 'react';
import Row from 'react-bootstrap/Row';

class BenchRow extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            pattern: props.pattern
        }
    }
    
    renderBenches = (children) => {

        let classroomWidth = children.length;
        var start = Math.round((classroomWidth - this.state.pattern.length) / 2);
        // korrigera start pga children ej hunnit med att uppdateras
        if(start < 0)
            start = 0;
        let renderedChildren = React.Children.map(children, child => {
                let numberInRow = child.props.number % classroomWidth;
                if(numberInRow >= start && this.state.pattern[numberInRow - start] === 1)
                    return React.cloneElement(child, {isSelected: true});
                return child;
            }
        )
        return renderedChildren;
    };

    render() {
        return (
            <Row key={"row_" + this.props.number}>
                {this.renderBenches(this.props.children)}
            </Row>
        );
    }
}

export default BenchRow;