import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class SelectionWithReplacementModal extends Component {
    
    constructor(props) {
        super(props);
    }
    
    render () {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Slumpad elev med återläggning
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="selectedStudentWithReplacementModal"></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.props.onClickButton}>Med återläggning</Button>
                    <Button variant="outline-dark" onClick={this.props.onHide}>Stäng</Button>
                </Modal.Footer>
            </Modal>
        );
    }
} export default SelectionWithReplacementModal;
