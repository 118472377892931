import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import Bench from './Bench';
import Classroom from './Classroom';
import BenchRow from './BenchRow';

import '../css/seating.css';

class Seating extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            seatingWidth: 10,
            chosenPreset: "2-2-2",
            uniqueId: 0,
            benchRows: []
        }
        this.handleRotateClick = this.handleRotateClick.bind(this);
        this.handleLoadPresetClick = this.handleLoadPresetClick.bind(this);
    }

    componentDidMount() {
        const rows = this.generateBenches();
        this.setState({
            benchRows: rows
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // Generate new rows if the seating width has changed.
        if (this.state.seatingWidth !== prevState.seatingWidth) {
            // Clear seating area if rows was just updated.
            if(this.state.benchRows === prevState.benchRows)
                this.clearSeatingArea();
            const rows = this.generateBenches();
            this.setState({
                benchRows: rows
            });
        }
    }

    handleWidthChange = (e) => {
        this.setState({
            seatingWidth: e.target.value
        });
    }

    handlePresetChange = (e) => {

        this.setState({
            chosenPreset: e.target.value
        });
    }

    handlePlaceStudentsClick() {
        window.distributeStudents();
    }

    handleRotateClick() {
        window.reverseChildNodes();
    }

    handleLoadPresetClick() {
        this.clearSeatingArea();
        
        let currentWidth = this.state.seatingWidth;

        // Adjust width of seating area if needed.
        if(this.state.chosenPreset === "2-2-2-2") {
            if(currentWidth < 11) {
                currentWidth = 11;
            }
        } else if(this.state.chosenPreset === "6-6") {
            if(currentWidth < 13) {
                currentWidth = 13;
            }
        }
        
        let pattern = this.generatePattern(this.state.chosenPreset);
        let updatedBenchRows = this.state.benchRows;
        
        updatedBenchRows = updatedBenchRows.map(row => {
            // Add pattern to every other row.
            if(row.props.number % 2 !== 1) {
                return React.cloneElement(row, {pattern: pattern});
            }
            return row;
        })

        this.setState(
            {
                seatingWidth: currentWidth,
                benchRows: updatedBenchRows
            }
        )
    }
    /**
     * Generates an array of ones (selected) and zeros (selecte) from the preset string.
     * E.g. "2-2-2" results in [1,1,0,1,1,0,1,1].
     * @param {String} presetString 
     */
    generatePattern(presetString) {
        var pattern = [];

        for (var i = 0; i < presetString.length; i++) {
            if(presetString.charAt(i) === '-') {
                pattern.push(0);
            } else {
                let length = parseInt(presetString.charAt(i), 10);
                for (var j = 0; j < length; j++) {
                    pattern.push(1);
                }
            }
        }

        return pattern;
    }

    generateBenches() {
        const nrOfRows = 10;
        const nrOfCols = this.state.seatingWidth;
        const rows = [];
        var items = [];

        for (let i = 0; i < nrOfRows; i++) {
            items = [];
            
            for (let j = 0; j < nrOfCols; j++) {
                items.push(<Bench isSelected={false} key={i * nrOfCols + j} number={i * nrOfCols + j}/>);
            }
            
            rows.push(<BenchRow pattern={[0]} key={i} number={i}>{items}</BenchRow>);
        }

        return rows;                             
    }

    clearSeatingArea() {
        let id = this.state.uniqueId == 0 ? 1 : 0;
        this.setState({
            uniqueId: id
        });
    }

    render() {
        
        return (
            <>
            <h2>Platsplacering i klassrum</h2>
            <Button variant="success" size="sm" type="button" className="seating-control" onClick={this.handlePlaceStudentsClick}>
                <i class="bi-shuffle"></i> Slumpa elevplacering
            </Button>
            <Button variant="success" size="sm" type="button" className="seating-control" onClick={this.handleRotateClick}>
            <i class="bi-arrow-repeat"></i> Rotera klassrummet 180
            </Button>
            <Container>
                <Row className="justify-content-center">
                    <Col xs="auto" className="seating-control">
                        <p>
                            Klassrumsbredd:
                        </p>
                    </Col>
                    <Col xs="auto" className="seating-control">
                        <select value={this.state.seatingWidth} onChange={this.handleWidthChange}>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                        </select>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs="auto" className="seating-control">
                        <p>
                            Förlagrade bänkplaceringar:
                        </p>
                    </Col>
                    <Col xs="auto" className="seating-control">
                        <select value={this.state.chosenPreset} onChange={this.handlePresetChange}>
                                <option>2-2-2</option>
                                <option>2-3-2</option>
                                <option>4-4</option>
                                <option>2-2-2-2</option>
                                <option>6-6</option>
                        </select>
                    </Col>
                    <Col xs="auto" className="seating-control">
                        <Button variant="success" size="sm" type="button" onClick={this.handleLoadPresetClick}>
                            Ladda bänkplacering
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container id="classroomContainer">
                <Classroom key={this.state.uniqueId}>
                    {this.state.benchRows} 
                </Classroom>
            </Container>
            </>
        )
    }
}

export default Seating;   