import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

class Grouping extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            groupSize: 3,
            groups: []
        }
    }

    handleGroupSizeChange = (e) => {
        this.setState({
            groupSize: e.target.value
        });
    }
    
    handleCombineIntoGroupsClick = () => {
        window.combineIntoGroups(this.state.groupSize);
    }

    handleEvenOutGroupsClick = () => {
        console.log(this.state.groupSize);
        window.distributeStudentsMoreEvenly(this.state.groupSize);
    }

    render() {
        
        return (
            <>
            <h2>Dela in i grupper</h2>
            <Container className="no-top-padding">
                <Row className="justify-content-center">
                    <Col xs="auto" className="grouping-control">
                        <p>
                            Antal elever per grupp:
                        </p>
                    </Col>
                    <Col xs="auto" className="grouping-control">
                        <select id="groupSize" value={this.state.groupSize} onChange={this.handleGroupSizeChange}>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                        </select>
                    </Col>
                </Row>
            </Container>
            <Button variant="success" size="sm" type="button" onClick={this.handleCombineIntoGroupsClick}>
            <i class="bi-justify"></i> Dela in i grupper
            </Button>
            <Container id="groupsContainer">
                <h5>Grupper:</h5>
                <div id="evenOut"></div>
                <div id="groups"></div>
            </Container>
            </>
        )
    }
}

export default Grouping;   