import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class SelectionWithoutReplacementModal extends Component {
    
    constructor(props) {
        super(props);
    }
    
    render () {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Slumpad elev utan återläggning
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div id="selectedStudentWithoutReplacementModal"></div>
                <Button id="returnStudentButtonModal" style={{display: 'none'}} variant="success" size="sm" onClick={() => window.returnStudent()}>
                    Lägg tillbaka som slumpbar
                </Button>
                <br />
                <label>Redan framslumpade elever: </label>
                <div id="previouslySelectedStudentsModal"></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.props.onClickButton}>Utan återläggning</Button>
                    <Button variant="outline-dark" onClick={this.props.onHide}>Stäng</Button>
                </Modal.Footer>
            </Modal>
        );
    }
} export default SelectionWithoutReplacementModal;
    