import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Bench from './Bench';
import '../css/seating.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class Classroom extends React.Component {
    
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <>
                {this.props.children}
            </>
        )
    }
}

export default Classroom;   