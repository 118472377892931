import React from 'react';
import {Link} from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'

class NavBarTop extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "/"
        };
    }

    handleSelect = (selectedKey) => {
        this.setState({activeKey: selectedKey});
        if(selectedKey == "guide")
            window.tourOfSite();
    }

    render() {
        return (
            <Navbar>
                <Navbar.Brand href="https://www.katederundervisning.se">
                        <Image src="logo60x83.png"></Image>
                </Navbar.Brand>   
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav variant="pills" className="justify-content-end" activeKey={this.state.activeKey} onSelect={this.handleSelect}>
                        <LinkContainer to="/">
                            <Nav.Item>
                                <Nav.Link href="/">Hem</Nav.Link>
                            </Nav.Item>
                        </LinkContainer>
                        <LinkContainer to="/contact">
                            <Nav.Item>
                                <Nav.Link href="/contact">Kontakt</Nav.Link>
                            </Nav.Item>
                        </LinkContainer>
                        <Nav.Item>
                            <Nav.Link eventKey="guide">Användarguide</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default NavBarTop;   