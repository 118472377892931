import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import SelectionWithoutReplacementModal from './SelectionWithoutReplacementModal';

class SelectionWithoutReplacement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
          };
    }

    render () {
        return(
            <>
            <Card bg={'light'}>
                <Card.Body>
                    <Card.Title>
                        Slumpad elev utan återläggning:
                    </Card.Title>
                    <div id="selectedStudentWithoutReplacement"></div>
                    <Button id="returnStudentButton" variant="success" style={{display: 'none'}} className="btn-xxs" onClick={() => window.returnStudent()}>
                        <i class="bi-box-arrow-in-right"></i> Lägg tillbaka som slumpbar
                    </Button>
                    <br/>
                    <label>Redan framslumpade elever: </label>
                    <div id="previouslySelectedStudents"></div>
                    <Button variant="success" className="btn-xxs popup" onClick={() => this.setState({modalShow: true})}>
                        <i class="bi-arrows-angle-expand"></i> Öppna som popup
                    </Button>
                    <SelectionWithoutReplacementModal
                        show={this.state.modalShow}
                        onHide={() => this.setState({modalShow: false})}
                        onShow={() => {window.transferContent('selectedStudentWithoutReplacement', 'selectedStudentWithoutReplacementModal'); window.transferContent('previouslySelectedStudents', 'previouslySelectedStudentsModal');}}
                        onClickButton={() => window.randomStudentMemory()}
                    />
                </Card.Body>
            </Card>
            </>
        );
    }
} export default SelectionWithoutReplacement;