import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import Checkbox from 'react-bootstrap/FormCheck';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import Seating from './Seating';
import Grouping from './Grouping';
import RandomSelection from './RandomSelection';

class Tools extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isChecked: true};
    }

    handleChecked = () => {
        this.setState({isChecked: !this.state.isChecked});
    }

    render () {
        return(
            <Fragment>
                <h1>Mitt Klassrum</h1>
                <p>Klistra in din elevgrupp i textrutan från till exempel Excel. En elev per rad.</p>
                <Form>
                    <Form.Group>
                        <Form.Label><Checkbox label="Förminska/förstora textrutan" onChange={this.handleChecked}/></Form.Label>
                        <Form.Control as="textarea" rows={this.state.isChecked ? "20" : "1"} id="students"/>
                    </Form.Group>
                </Form>
                <h3>Välj verktyg</h3>
                <Accordion>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Slumpa enskilda elever
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <RandomSelection>
                                </RandomSelection>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            Dela in i grupper
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Grouping>
                                </Grouping>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2">
                            Platsplacering i klassrum
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <Seating>
                                </Seating>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Fragment>
        );
    }
}

export default Tools;