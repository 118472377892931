import React from 'react';
//import logo from './logo.svg';
import './App.css';
import './css/custom.css';


import {BrowserRouter} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'

import NavBarTop from "./components/NavBarTop";
import SiteRouter from './components/SiteRouter';

function App() {
  return (
    <BrowserRouter>
      <Container>
        <NavBarTop/>
        <Jumbotron className="text-center">      
          <SiteRouter/>
        </Jumbotron>  
      </Container>
    </BrowserRouter>
  );
}

export default App;
