import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import SelectionWithReplacementModal from './SelectionWithReplacementModal';

class SelectionWithReplacement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
          };
    }

    render () {
        return(
            <>
            <Card bg={'light'}>
                <Card.Body>
                    <Card.Title>
                        Slumpad elev med återläggning:
                    </Card.Title>
                    <div id="selectedStudentWithReplacement"></div>
                    <Button variant="success" className="btn-xxs popup" onClick={() => this.setState({modalShow: true})}>
                        <i class="bi-arrows-angle-expand"></i> Öppna som popup
                    </Button>
                    <SelectionWithReplacementModal
                        show={this.state.modalShow}
                        onHide={() => this.setState({modalShow: false})}
                        onShow={() => window.transferContent('selectedStudentWithReplacement', 'selectedStudentWithReplacementModal')}
                        onClickButton={() => window.randomStudent()}
                    />
                </Card.Body>
            </Card>
            </>
        );
    }
} export default SelectionWithReplacement;