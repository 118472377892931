import React from "react";
import {Switch, Route} from "react-router-dom";

import Tools from './Tools'
import Contact from './Contact'

export default function SiteRouter({state}) {

  return (
    <Switch>
        <Route exact path="/">
            <Tools/>
        </Route>
        <Route exact path="/contact" >
            <Contact/>
        </Route>
        <Route path="*" component={() => "404 NOT FOUND"}/>
    </Switch>  
  );
}