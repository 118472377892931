import React from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import SelectionWithReplacement from './SelectionWithReplacement';
import SelectionWithoutReplacement from './SelectionWithoutReplacement';
import Container from 'react-bootstrap/Container';

class RandomSelection extends React.Component {

    constructor(props) {
        super(props);
    }

    handleRandomStudentWithReplacementClick() {
        window.randomStudent();
    }

    handleRandomStudentWithoutReplacementClick() {
        window.randomStudentMemory();
    }

    render () {
        return(
            <>
                <h2>Slumpa enskild elev</h2>
                <Button variant="success" size="sm" type="button" className="seating-control" onClick={this.handleRandomStudentWithReplacementClick}>
                    <i class="bi-arrow-clockwise"></i> Med återläggning
                </Button>
                <Button variant="success" size="sm" type="button" className="seating-control" onClick={this.handleRandomStudentWithoutReplacementClick}>
                <i class="bi-pin-angle"></i> Utan återläggning
                </Button>
                <Container>
                <SelectionWithReplacement>
                </SelectionWithReplacement>
                <SelectionWithoutReplacement>
                </SelectionWithoutReplacement>
                </Container>
            </>
        );
    }
} export default RandomSelection;