import React from 'react';
import Col from 'react-bootstrap/Col';
import '../css/seating.css';

class Bench extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSelected: props.isSelected,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({isSelected: !this.state.isSelected});    
    }

    handleDrop = (event) => {
        window.drop(event);
    }

    handleDragOver = (event) => {
        window.allowDrop(event);
    }

    render() {    
        const id = 'table' + this.props.number;

        return (
            <Col className="align-self-center" key={this.props.number}>
                <div id={id} className={this.state.isSelected ? 'classroomTable-selected' : 'classroomTable'} onClick={this.handleClick} onDrop={(event) => this.handleDrop(event)} onDragOver={(event) => this.handleDragOver(event)}></div>
            </Col>
        )
    }
}

export default Bench;   